(function (){
	'use strict';
	var navBarButton = document.getElementById("navBarButton"),
		navBarMenu = document.getElementById("navBarMenu"),
		navBarMenuCL = navBarMenu.classList,
		formationMenu = document.getElementById("formationMenu"),
		formationButton = document.getElementById('formationButton'),
		scrollContainer = document.querySelector('.scroll'),
		lazyImages = document.querySelectorAll('img[data-url]'),
		
		resolution = window.screen.availWidth,
		imgFolder = "/public/images/" + (resolution < 721 ? "low/" : "high/") ;

	var showMenu = evt => formationMenu.parentNode.classList.add('open');

	var hideMenu = evt => formationMenu.parentNode.classList.remove('open');

	var toggleMenu = evt => {
		navBarMenuCL.remove('in');
		if(formationMenu.parentNode.classList.contains('open')){
			navBarMenu.style.height = "80px";
			hideMenu();
		}else {
			navBarMenu.style.height = "230px";
			showMenu();
		}
	}

	formationButton.addEventListener("mouseover", showMenu);
	scrollContainer.addEventListener("mouseover", hideMenu);
	formationButton.addEventListener("touchend", toggleMenu);

	navBarButton.addEventListener("touchend", _ => {
		var expanded = navBarButton.getAttribute('aria-expanded') === "true";
		if (expanded){
			navBarMenu.style.height = "0";
			hideMenu();
		}else {
			navBarMenuCL.remove('collapse');
			navBarMenuCL.add('collapsing');
			setTimeout(_ => navBarMenu.style.height = "80px", 0);//async call
		}
		navBarButton.setAttribute('aria-expanded', !expanded);
	});

	navBarMenu.addEventListener("transitionend", _ => {
		if (navBarMenuCL.contains('in')){
			navBarMenuCL.remove('in');
			navBarMenuCL.remove('collapsing');
			navBarMenuCL.add('collapse');
		}else {
			navBarMenuCL.add('in');
		}
	});
	var loc = location.pathname;
	if (loc.startsWith('/posts')){
		document.querySelector('li[data-url="/pages/blog/"').classList.add('active');
	}else if (loc.startsWith('/pages/formations')){
		document.querySelector('li[data-url="/pages/formations/"').classList.add('active');
	}else {
		var li = document.querySelector(`li[data-url="${loc}"]`);
		if (li){
			li.classList.add('active');
		}
	}

	if (navigator.share){
		const shareButtons = document.querySelectorAll('.webshare')
		var webShare = evt => navigator.share({title: document.title, text: 'Nouvel article', url: window.location.href})
		Array.from(shareButtons).forEach(node => {
			node.addEventListener("click", webShare);
			node.addEventListener("touchend", webShare);
		});
	}

	Array.from(lazyImages).forEach(node => setTimeout(() => node.src = imgFolder + node.getAttribute('data-url'), 1));
})();
